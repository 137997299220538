<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <select v-model="reqData.dateType" class="mr-5">
               <option value="reg">{{ $t('table.head.applyDate') }}</option>
               <option value="upd">{{ $t('table.head.updDate') }}</option>
             </select>
             <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
           </div>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title">
             <span>{{ $t('searchArea.searchCon') }}</span>
           </div>
           <div>
             <select class="mr-5" v-model="searchInputOption">
               <option value="siteId">{{ $t('searchArea.siteID') }}</option>
               <option value="siteName">{{ $t('searchArea.site') }}</option>
             </select>
             <input v-if="searchInputOption === 'siteId'" type="text" class="mr-5" v-model="reqData.siteId" :placeholder="$t('searchArea.input')" />
             <input v-if="searchInputOption === 'siteName'" type="text" class="mr-5" v-model="reqData.siteName" :placeholder="$t('searchArea.input')" />
             <button class="btn-search" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="tab-wrap">
        <button type="button" class="tab-item" :class="{ active: currentStatus === '0' }" @click="changeListType('0')">{{ $t('button.exchApply') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '2' }" @click="changeListType('2')">{{ $t('button.exchWait') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '1' }" @click="changeListType('1')">{{ $t('button.exchComplete') }}</button>
        <button type="button" class="tab-item" :class="{ active: currentStatus === '-1' }" @click="changeListType('-1')">{{ $t('button.exchCancel') }}</button>
      </div>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="list.length !== 0">
            <tr v-for="(item, idx) in list" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('company', item.siteId)">{{ item.siteId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('company', item.siteId)">{{ item.siteName }}</button>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.preInsuredAmt) }}</span>
              </td>
              <td>
                <span class="money-apply">{{ numberWithCommas(item.insuredAmt) }}</span>
              </td>
              <td>
                <span class="money-apply">{{ item.realInsuredAmt ? numberWithCommas(item.realInsuredAmt) : "-" }}</span>
              </td>
              <td v-if="item.insuredStatus === '1'">{{ numberWithCommas(Number(item.preInsuredAmt) + Number(item.realInsuredAmt)) }}</td>
              <td v-else>-</td>
              <td style="width: 15%">
                <span>{{ replaceDateT(item.requestDate) }}</span>
              </td>
              <td style="width: 15%">
                <span>{{ item.insuredStatus !== "0" && item.insuredStatus !== "2" ? replaceDateT(item.successDate) : "-" }}</span>
              </td>
              <td class="statusColor" :class="item.statusClass">{{ item.statusText }}</td>
              <td style="width: 8%">
                <div class="status-change-btn-wrap">
                  <button class="btn-innerTable btn-status-change wait" type="button" v-if="item.insuredStatus == '0'" @click="statusChange(item, '2')">{{$t('common.wait')}}</button>
                  <button class="btn-innerTable btn-status-change approve" type="button" v-if="item.insuredStatus == '2'" @click="statusChange(item, '1')">{{$t('common.exchange')}}</button>
                  <button class="btn-innerTable btn-status-change refuse" type="button" v-if="item.insuredStatus == '2'" @click="statusChange(item, '-1')">{{$t('searchArea.refu')}}</button>
                  <span v-else>-</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10">
                <span class="notice-tableDataEmpty">{{$t('txt.noData')}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="list.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import TableHead from "@/components/main/table/Head.vue";
import { insuredSubApplyList,insuredStatusChange } from "@/api/insured.js";
import Pagination from "@/components/common/Pagination";
import Memo from '@/components/common/memo'

export default {
  name: "InsuredApplyList",
  components: {
    TableHead,
    DateSelector,
    SearchFilter,
    Pagination,
    Memo
  },
  watch: {},
  data: function () {
    return {
      tableName: "Basic",
      headInfo: {
        fstColumn: false,
        dataList: ["companyId", "companyName", "preInsuredAmt", "insuredAmt", "realInsuredAmt", "postInsuredAmt", "applyDate", "updDate", "status", "option"]
      },
      reqData: {
        siteId: "",
        siteName: "",
        insuredType: "-1",
        insuredStatus: "0",
        dateType: "reg",
        startDate: "",
        endDate: "",
        count_per_list: "30",
        page: 1
      },
      searchInputOption: "siteId",
      dateConfigs: {
        enableTime: true,
        dateFormat: "Y-m-d H:i"
      },
      startDefault: "",
      endDefault: "",
      list: [],
      pageInfo: {},
      currentStatus: ''
    };
  },
  methods: {
    pageSeach() {
      this.setTableData(1);
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(0, 0, 0);
      } else {
        _date = date[0];
        _date.setSeconds(0);
      }
      this.startDefault = _date;
      this.reqData.startDate = this.getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
        _date.setHours(23, 59, 59);
      } else {
        _date = date[0];
        _date.setSeconds(59);
      }
      this.endDefault = _date;
      this.reqData.endDate = this.getDateStr(_date, "yyyy-MM-dd HH:mm:ss");
    },
    async changeListType(status) {
      this.reqData.insuredStatus = status;
      this.currentStatus = status
      await this.setTableData(1)
    },
    async setTableData(pageNum) {
      const req = this.reqData;
      if (pageNum) {
        req.page = pageNum;
      }
      const res = await insuredSubApplyList(req);
      const list = res.data.list;
      const pageInfo = res.data.pageInfo;
      if (list) {
        this.list = this.dataConvertor(list);
      }
      if (pageInfo) {
        this.pageInfo = pageInfo;
      }
    },
    setPayment(dataObj) {
      const _creditAmt = prompt(`보험금 환전 금액`, Math.abs(dataObj.insuredAmt));
      const trigger = {
        msg: "",
        prog: false
      };
      if (_creditAmt) {
        if (isNaN(_creditAmt)) {
          trigger.msg = "숫자만 입력해주세요.";
        } else if (_creditAmt <= 0) {
          trigger.msg = "환전 최소 금액은 0 이상 입니다. 다시 입력해주세요.";
        } else if (_creditAmt == null || _creditAmt == undefined) {
          trigger.msg = "";
        } else {
          trigger.prog = confirm(`보험금 ${this.numberWithCommas(_creditAmt)}원을 환전합니다.`);
          if (trigger.prog) {
            trigger.msg = _creditAmt;
          } else {
            trigger.msg = "보험 환전 취소";
          }
        }
      } else {
        trigger.msg = "";
        trigger.prog = false;
      }

      return trigger;
    },
    async statusChange(item, status) {
      let reqModel = {
        insuredIdx: item.insuredIdx,
        siteId: item.siteId,
        insuredType: item.insuredType,
        insuredAmt: item.insuredAmt,
        insuredStatus: status
      };
      switch (status) {
        case "1":
          const preCheck = this.setPayment(reqModel);
          if (preCheck.prog) {
            reqModel.insuredAmt = preCheck.msg;
          } else {
            if (preCheck.msg) {
              alert(preCheck.msg);
            }
            return;
          }
          break;
      }
      const res = await insuredStatusChange(reqModel);
      if (res.resultCode === "0") {
        if (reqModel.insuredStatus === "1") {
          alert("보험금 환전 완료");
        } else {
          alert("보험금 상태 변경 완료");
        }
      } else {
        if (reqModel.insuredStatus === "1") {
          alert("보험금 환전에 실패 했습니다. 다시 시도해주세요.");
        } else {
          alert("보험금 상태 변경에 실패 했습니다. 다시 시도해주세요.");
        }
      }
      this.setTableData();
    },
    dataConvertor(dataList) {
      // console.log(dataList);
      const _dataList = dataList;
      if (!dataList.length || dataList.length === 0) {
        return _dataList;
      }
      _dataList.forEach(items => {
        const status = items.insuredStatus;
        let statusText = "";
        // - 0:요청, 1:완료, 2:대기 , -1"거부
        if (status === "0") {
          statusText = "환전신청";
          items.statusClass = "apply";
        } else if (status === "1") {
          statusText = "환전완료";
          items.statusClass = "approve";
        } else if (status === "2") {
          statusText = "대기";
          items.statusClass = "wait";
        } else if (status === "-1") {
          statusText = "환전거절";
          items.statusClass = "refuse";
        }
        items.statusText = statusText;
      });
      return _dataList;
    }
  },
  async created() {
    this.currentStatus = this.$route.query.status || this.status
    if (!this.currentStatus) {
      this.currentStatus = '0'
    }
    this.setStartDate();
    this.setEndDate();
    await this.setTableData();
  }
};
</script>

<style scoped>
.statusColor {
  font-weight: 700;
}
.statusColor.apply {
  color: #ef8c1d;
}
.statusColor.approve {
  color: blue;
}
.statusColor.refuse {
  color: red;
}
.statusColor.wait {
  color: green;
}
</style>
